import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useRef } from "react";

export default function AllAvailableTimes({
  allAvailableTimes,
  mostAvailableParticipantCount,
  participantCount,
  url,
}) {
  const targetRef = useRef(null);
  const toast = useToast();
  const onCopy = () => {
    const times = allAvailableTimes
      .map(
        ([date, times]) =>
          `${date.getMonth() + 1}/${date.getDate()} (${
            "일월화수목금토"[date.getDay()]
          })\n${times.map((time) => `· ${time}`).join("\n")}`
      )
      .join("\n");
    const text =
      mostAvailableParticipantCount === participantCount
        ? `📌 타임픽에서 모두 되는 시간대\n${times}\n\n${url}`
        : `📌 타임픽에서 가장 많은 사람(${mostAvailableParticipantCount}명)이 되는 시간대\n${times}\n\n${url}`;
    try {
      navigator.clipboard.writeText(text);
      toast({
        position: "bottom",
        duration: 1600,
        render: () => (
          <Box
            width="100%"
            marginBottom="16px"
            display="flex"
            justifyContent="center"
          >
            <Text
              paddingX="16px"
              paddingY="10px"
              borderRadius="8px"
              color="white"
              backgroundColor="blue.500"
              textAlign="center"
              fontFamily="PRT-SemiBold"
              fontSize="16px"
            >
              {mostAvailableParticipantCount === participantCount
                ? "모두가 되는 시간대를 복사하였습니다."
                : "가장 많은 사람이 되는 시간대를 복사하였습니다."}
            </Text>
          </Box>
        ),
      });
    } catch (error) {
      toast({
        position: "bottom",
        duration: 1600,
        render: () => (
          <Box
            width="100%"
            marginBottom="16px"
            display="flex"
            justifyContent="center"
          >
            <Text
              paddingX="16px"
              paddingY="10px"
              borderRadius="8px"
              color="white"
              backgroundColor="red.400"
              textAlign="center"
              fontFamily="PRT-SemiBold"
              fontSize="16px"
            >
              복사에 실패하였습니다.
            </Text>
          </Box>
        ),
      });
    }
  };

  return (
    <VStack width="90%" ref={targetRef} spacing="18px">
      {mostAvailableParticipantCount === participantCount ||
      mostAvailableParticipantCount === 0 ? (
        <VStack width="100%" spacing="8px">
          <HStack width="100%" justifyContent="space-between">
            <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
              모두가 되는 시간대
            </Text>
            {mostAvailableParticipantCount === participantCount &&
              allAvailableTimes.length > 0 && (
                <Button
                  colorScheme="blue"
                  fontFamily="PRT-Light"
                  fontSize="14px"
                  _focus={{ boxShadow: "none" }}
                  onClick={onCopy}
                >
                  복사
                </Button>
              )}
          </HStack>
          {mostAvailableParticipantCount === participantCount &&
          allAvailableTimes.length > 0 ? (
            <VStack width="100%" spacing="16px">
              {allAvailableTimes.map(([date, times], index) => (
                <VStack key={index} width="100%" spacing="8px">
                  <Text
                    width="100%"
                    textAlign="left"
                    fontFamily="PRT-SemiBold"
                    fontSize="16px"
                  >{`${date.getMonth() + 1}/${date.getDate()} (${
                    "일월화수목금토"[date.getDay()]
                  })`}</Text>
                  <VStack width="100%">
                    {times.map((time, index) => (
                      <Text
                        key={index}
                        width="100%"
                        fontFamily="PRT-Medium"
                        fontSize="14px"
                      >
                        · {time}
                      </Text>
                    ))}
                  </VStack>
                </VStack>
              ))}
            </VStack>
          ) : (
            <Text fontFamily="PRT-Medium" fontSize="16px">
              {participantCount > 0
                ? "아무도 되는 시간이 없습니다."
                : "아직 참여한 인원이 없습니다."}
            </Text>
          )}
        </VStack>
      ) : (
        <VStack width="100%" spacing="16px">
          <HStack width="100%" justifyContent="space-between">
            <Text width="100%" fontFamily="PRT-SemiBold" fontSize="18px">
              {`가장 많은 사람이 되는 시간대 (${mostAvailableParticipantCount}명)`}
            </Text>
            <Button
              colorScheme="blue"
              fontFamily="PRT-Light"
              fontSize="14px"
              _focus={{ boxShadow: "none" }}
              isDisabled={allAvailableTimes.length === 0}
              onClick={onCopy}
            >
              복사
            </Button>
          </HStack>
          <VStack width="100%" spacing="8px">
            {allAvailableTimes.map(([date, times], index) => (
              <VStack key={index} width="100%" spacing="8px">
                <Text
                  width="100%"
                  textAlign="left"
                  fontFamily="PRT-SemiBold"
                  fontSize="16px"
                >{`${date.getMonth() + 1}/${date.getDate()} (${
                  "일월화수목금토"[date.getDay()]
                })`}</Text>
                <VStack width="100%">
                  {times.map((time, index) => (
                    <Text
                      key={index}
                      width="100%"
                      fontFamily="PRT-Medium"
                      fontSize="14px"
                    >
                      · {time}
                    </Text>
                  ))}
                </VStack>
              </VStack>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
}
