import { Accordion } from "@chakra-ui/react";
import { generateArray } from "../../utils";
import ParticipantWeekAvailability from "./ParticipantWeekAvailability";

export default function ParticipantAvailability({ data }) {
  const { dates } = data;

  const array = generateArray(dates.length);

  return (
    <Accordion width="100%" allowMultiple={true} defaultIndex={[0]}>
      {array.map((n, i) => (
        <ParticipantWeekAvailability
          key={i}
          weekNumber={n}
          weekDates={dates.slice(n, n + 7)}
          data={data}
        />
      ))}
    </Accordion>
  );
}
