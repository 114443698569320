import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { login } from "../../api";

export default function LoginModal({
  isOpen,
  onClose,
  pk,
  code,
  setParticipantPk,
}) {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");

  const queryClient = useQueryClient();
  const loginMutation = useMutation(login, {
    onSuccess: ({ participantPk }) => {
      setParticipantPk(participantPk);
      queryClient.refetchQueries(["participant", pk, code, participantPk]);
      onClose();
    },
    onError: () => {
      setErrorText("잘못된 비밀번호입니다.");
    },
  });
  const onLogin = () => {
    setErrorText("");
    loginMutation.mutate({
      pk,
      code,
      data: {
        name: name.trim(),
        password,
      },
    });
  };

  const nameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const handleNameInputKeyPress = (e) => {
    if (name && e.key === "Enter" && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  };

  const handlePasswordInputKeyPress = (e) => {
    if (name && e.key === "Enter" && passwordInputRef.current) {
      passwordInputRef.current.blur();
      onLogin();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="90%" borderRadius="10px" paddingBottom="10px">
        <ModalHeader>
          <Text fontFamily="PRT-SemiBold" fontSize="22px">
            내 정보 입력
          </Text>
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <VStack width="100%">
            <Input
              className="input"
              ref={nameInputRef}
              autoFocus
              type="text"
              placeholder="이름"
              fontFamily="PRT-Medium"
              maxLength="40"
              spellCheck={false}
              value={name}
              isDisabled={loginMutation.isLoading}
              onChange={(event) => {
                setName(event.target.value.trimStart());
                setErrorText("");
              }}
              onKeyDown={handleNameInputKeyPress}
            />
            <Input
              className="input"
              ref={passwordInputRef}
              type="password"
              placeholder="비밀번호 (선택사항)"
              fontFamily="PRT-Medium"
              maxLength="140"
              value={password}
              isDisabled={loginMutation.isLoading}
              onChange={(event) => {
                setPassword(event.target.value);
                setErrorText("");
              }}
              onKeyDown={handlePasswordInputKeyPress}
            />
            {errorText ? (
              <Text color="red.500" fontFamily="PRT-Medium" fontSize="14px">
                {errorText}
              </Text>
            ) : null}
            <Button
              width="100%"
              colorScheme="blue"
              isDisabled={!name}
              isLoading={loginMutation.isLoading}
              onClick={onLogin}
            >
              <Text fontFamily="PRT-Medium" fontSize="16px">
                확인
              </Text>
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
