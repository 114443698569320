import { HStack, Select, Text, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { formatTime } from "../../utils";

function getTimeArray(startTime, endTime) {
  return Array.from(
    { length: endTime - startTime + 1 },
    (_, i) => i + startTime
  );
}

export default function SelectTime({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) {
  const [endTimeArray, setEndTimeArray] = useState(getTimeArray(startTime, 24));

  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);

  const handleSelectStartTime = () => {
    if (startTimeRef.current != null) {
      startTimeRef.current.blur();
    }
  };
  const handleSelectEndTime = () => {
    if (endTimeRef.current != null) {
      endTimeRef.current.blur();
    }
  };

  return (
    <VStack width="100%" spacing="12px">
      <HStack width="100%" spacing="0px">
        <Select
          width="85%"
          ref={startTimeRef}
          fontFamily="PRT-Medium"
          value={startTime}
          onChange={(event) => {
            handleSelectStartTime();
            const value = parseInt(event.target.value);
            setStartTime(value);
            setEndTimeArray(getTimeArray(value + 1, 24));
            if (value >= endTime) {
              setEndTime(24);
            }
          }}
        >
          {getTimeArray(0, 23).map((time, index) => (
            <option className="option" key={index} value={time}>
              {`${time}시 (${formatTime(time)})`}
            </option>
          ))}
        </Select>
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          부터
        </Text>
      </HStack>
      <HStack width="100%" spacing="0px">
        <Select
          width="85%"
          ref={endTimeRef}
          fontFamily="PRT-Medium"
          value={endTime}
          onChange={(event) => {
            handleSelectEndTime();
            setEndTime(parseInt(event.target.value));
          }}
        >
          {endTimeArray.map((time, index) => (
            <option
              className="option"
              key={index}
              value={time}
            >{`${time}시 (${formatTime(time)})`}</option>
          ))}
        </Select>
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          까지
        </Text>
      </HStack>
    </VStack>
  );
}
