import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDragSelectCalendar } from "../../hooks/useDragSelectCalendar";
import { getMonthData } from "../../utils";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function Calendar({ calendar, setCalendar }) {
  const now = new Date();
  const thisYear = now.getFullYear();
  const thisMonth = now.getMonth() + 1;
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(thisMonth);

  useEffect(() => {
    if (calendar.length === 0) {
      let monthData = getMonthData(thisYear, thisMonth);
      if (monthData.length <= 3) {
        if (month !== 12) {
          monthData = [...monthData, ...getMonthData(thisYear, thisMonth + 1)];
          setMonth(thisMonth + 1);
        } else {
          monthData = [...monthData, ...getMonthData(thisYear + 1, 1)];
          setYear(thisYear + 1);
          setMonth(1);
        }
      }
      setCalendar(monthData);
    }
  });

  const calendarRef = useDragSelectCalendar(calendar, setCalendar);

  return (
    <VStack width="100%" spacing="4px">
      <VStack
        className="calendar"
        width="100%"
        spacing="10px"
        ref={calendarRef}
      >
        {calendar.length > 0
          ? calendar.map((row, rowIndex) =>
              row instanceof Array ? (
                <HStack
                  key={rowIndex}
                  className="week"
                  width="100%"
                  spacing="0px"
                >
                  {row.map((cell, columnIndex) => (
                    <Box
                      key={columnIndex}
                      className="date"
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      {cell !== null ? (
                        <Text
                          className="innerDate"
                          width="40px"
                          height="40px"
                          borderRadius="20px"
                          fontFamily="PRT-Medium"
                          fontSize="16px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color={cell[1] === null ? "gray.300" : "black"}
                          backgroundColor={cell[1] ? "blue.100" : "white"}
                        >
                          {cell[0].getDate()}
                        </Text>
                      ) : (
                        <Box className="innerDate" width="40px" height="40px" />
                      )}
                    </Box>
                  ))}
                </HStack>
              ) : (
                <VStack
                  key={rowIndex}
                  className="week"
                  width="100%"
                  spacing="10px"
                >
                  <Text
                    width="100%"
                    paddingTop={rowIndex > 0 ? "12px" : "0px"}
                    fontFamily="PRT-ExtraBold"
                    fontSize="20px"
                    borderBottomWidth="2px"
                    borderColor="black"
                  >
                    {row}
                  </Text>
                  <HStack width="100%" spacing="0px">
                    {"일월화수목금토".split("").map((day, index) => (
                      <Box key={index} width="100%">
                        <Text
                          with="100%"
                          fontFamily="PRT-SemiBold"
                          fontSize="16px"
                          color={
                            index === 0
                              ? "red.500"
                              : index === 6
                              ? "blue.500"
                              : "black"
                          }
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {day}
                        </Text>
                      </Box>
                    ))}
                  </HStack>
                </VStack>
              )
            )
          : null}
      </VStack>
      <MdKeyboardArrowDown
        size="40px"
        cursor="pointer"
        color="black"
        onClick={() => {
          let newMonthData;
          if (month !== 12) {
            newMonthData = getMonthData(year, month + 1);
            setMonth(month + 1);
          } else {
            newMonthData = getMonthData(year + 1, 1);
            setYear(year + 1);
            setMonth(1);
          }
          setCalendar([...calendar, ...newMonthData]);
        }}
      />
    </VStack>
  );
}
