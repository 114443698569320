import {
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Calendar from "../componenets/home/Calendar";
import CreateEventButton from "../componenets/home/CreateEventButton";
import SelectTime from "../componenets/home/SelectTime";
import { hasSelectedDate } from "../utils";
import useTopScroll from "../hooks/useTopScroll";
import { MdOutlineClose } from "react-icons/md";

export default function Home() {
  useTopScroll();

  const [eventName, setEventName] = useState("타임픽 모임");
  const [startTime, setStartTime] = useState(9);
  const [endTime, setEndTime] = useState(24);
  const [calendar, setCalendar] = useState([]);
  const [hasValidData, setHasValidData] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (
      eventName.length > 0 &&
      startTime != null &&
      endTime != null &&
      hasSelectedDate(calendar)
    ) {
      setHasValidData(true);
    } else {
      setHasValidData(false);
    }
  }, [eventName, startTime, endTime, calendar]);

  return (
    <VStack width="100%">
      <VStack width="90%" spacing="24px">
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            모임 이름 입력
          </Text>
          <InputGroup>
            <Input
              ref={inputRef}
              className="input"
              type="text"
              placeholder="모임 이름을 입력하세요."
              fontFamily="PRT-Medium"
              maxLength="140"
              spellCheck={false}
              value={eventName}
              onChange={(event) => {
                setEventName(event.target.value.trimStart());
              }}
            />
            {eventName === "" || (
              <InputRightElement>
                <MdOutlineClose
                  size="18px"
                  color="black"
                  onClick={() => {
                    setEventName("");
                    inputRef.current.focus();
                  }}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </VStack>
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            후보 날짜 선택
          </Text>
          <Calendar calendar={calendar} setCalendar={setCalendar} />
        </VStack>
        <VStack width="100%" spacing="8px">
          <Text width="100%" fontFamily="PRT-SemiBold" fontSize="22px">
            후보 시간대 선택
          </Text>
          <SelectTime
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
          />
        </VStack>
      </VStack>
      <CreateEventButton
        eventName={eventName.trim()}
        startTime={startTime}
        endTime={endTime}
        calendar={calendar}
        hasValidData={hasValidData}
      />
    </VStack>
  );
}
