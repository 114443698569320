import { Box, Button, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createEvent } from "../../api";
import { extractSelectedDates } from "../../utils";

export default function CreateEventButton({
  eventName,
  startTime,
  endTime,
  calendar,
  hasValidData,
}) {
  const navigate = useNavigate();
  const createEventMutation = useMutation(createEvent, {
    onSuccess: ({ id }) => {
      navigate(`/event/${id}`);
    },
  });
  return (
    <Box
      width="100%"
      maxWidth="480px"
      position="fixed"
      bottom="0px"
      backgroundColor="white"
    >
      <Button
        width="100%"
        height="56px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        colorScheme={hasValidData ? "blue" : "blackAlpha"}
        isDisabled={!hasValidData}
        isLoading={createEventMutation.isLoading}
        borderRadius="0px"
        borderTopWidth={hasValidData ? "1px" : "0px"}
        borderColor="gray.200"
        onClick={() => {
          createEventMutation.mutate({
            data: {
              name: eventName,
              startTime: parseInt(startTime),
              endTime: parseInt(endTime),
              dates: extractSelectedDates(calendar),
            },
          });
        }}
      >
        <Text fontFamily="PRT-SemiBold" fontSize="24px">
          모임 만들기
        </Text>
      </Button>
    </Box>
  );
}
